// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/theme_icons/arrow-right.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/theme_icons/arrow-left.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VueCarousel-navigation-button[data-v-4b87a0f4]{position:absolute;top:0;box-sizing:border-box;text-decoration:none;opacity:0;transform:none;width:50%;height:100%}.VueCarousel-navigation-next[data-v-4b87a0f4]{right:0;cursor:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 20 20,auto}.VueCarousel-navigation-prev[data-v-4b87a0f4]{left:0;cursor:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 20 20,auto}.VueCarousel-navigation--disabled[data-v-4b87a0f4]{opacity:0;cursor:default}", "",{"version":3,"sources":["webpack://./src/plugins/carousel/Navigation.vue"],"names":[],"mappings":"AACA,gDACE,iBAAA,CACA,KAAA,CACA,qBAAA,CACA,oBAAA,CACA,SAAA,CACA,cAAA,CACA,SAAA,CACA,WAAA,CAGF,8CACE,OAAA,CACA,yDAAA,CAGF,8CACE,MAAA,CACA,yDAAA,CAGF,mDACE,SAAA,CACA,cAAA","sourcesContent":["\n.VueCarousel-navigation-button {\n  position: absolute;\n  top: 0;\n  box-sizing: border-box;\n  text-decoration: none;\n  opacity: 0;\n  transform: none;\n  width: 50%;\n  height: 100%;\n}\n\n.VueCarousel-navigation-next {\n  right: 0;\n  cursor: url('~assets/theme_icons/arrow-right.png') 20 20, auto;\n}\n\n.VueCarousel-navigation-prev {\n  left: 0;\n  cursor: url('~assets/theme_icons/arrow-left.png') 20 20, auto;\n}\n\n.VueCarousel-navigation--disabled {\n  opacity: 0;\n  cursor: default;\n}\n\n/*\n  .VueCarousel-navigation--is-full-width {\n    width: 100%;\n    z-index: 1;\n  }\n  */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
