export default [
  {
    id: 'cookcouture',
    href: '/cookcouture',
    title: 'CookCouture',
    img: require('./../assets/case-studies/cookcouture/main.jpg'),
  },
  {
    id: 'havelaar',
    href: '/havelaar',
    title: 'Fairtrade Max Havelaar',
    img: require('./../assets/case-studies/havelaar/main.jpg'),
  },
  {
    id: 'nebelspalter',
    href: '/nebelspalter',
    title: 'Nebelspalter',
    img: require('./../assets/case-studies/nebelspalter/main.jpg'),
  },
  {
    id: 'stadtspital',
    href: '/stadtspital',
    title: 'Stadtspital Zürich',
    img: require('./../assets/case-studies/stadtspital/main.jpg'),
  },
  {
    id: 'schutzundrettung',
    href: '/schutzundrettung',
    title: 'Schutz & Rettung',
    img: require('./../assets/case-studies/schutzundrettung/main.jpg'),
  },
  {
    id: 'doppelleu',
    href: '/doppelleu',
    title: 'Doppelleu Boxer',
    img: require('./../assets/case-studies/doppelleu/main.jpg'),
  },
  {
    id: 'lungezuerisch',
    href: '/lungezuerisch',
    title: 'Lunge Zürich',
    img: require('./../assets/case-studies/lungezuerich/main.jpg'),
  },
  {
    id: 'rentarentner',
    href: '/rentarentner',
    title: 'Rent a Rentner',
    img: require('./../assets/case-studies/rentarentner/main.jpg'),
  },
  {
    id: 'sandozglobal',
    href: '/sandozglobal',
    title: 'Sandoz Global',
    img: require('./../assets/case-studies/sandozglobal/main.jpg'),
  },
  {
    id: 'sandozschweiz',
    href: '/sandozschweiz',
    title: 'Sandoz Schweiz',
    img: require('./../assets/case-studies/sandozschweiz/main.jpg'),
  },
  {
    id: 'teexpress',
    href: '/teexpress',
    title: 'Sidroga Teexpress',
    img: require('./../assets/case-studies/teexpress/main.jpg'),
  },
  {
    id: 'sidrosan',
    href: '/sidrosan',
    title: 'Sidrosan',
    img: require('./../assets/case-studies/sidrosan/main.jpg'),
  },
  {
    id: 'sobu',
    href: '/sobu',
    title: 'Sobu',
    img: require('./../assets/case-studies/sobu/main.jpg'),
  },
  {
    id: 'steviasweet',
    href: '/steviasweet',
    title: 'Stevia Sweet',
    img: require('./../assets/case-studies/steviasweet/main.jpg'),
  },
  {
    id: 'viivhealthcare',
    href: '/viivhealthcare',
    title: 'ViiV Healthcare',
    img: require('./../assets/case-studies/viivhealthcare/main.jpg'),
  },
]
