import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Imprint from '../views/Imprint.vue'
import Datenschutz from '../views/Datenschutz.vue'
import Doppelleu from '../views/Doppelleu.vue'
import Teeexpress from '../views/Teexpress.vue'
import Viivhealthcare from '../views/Viivhealthcare.vue'
import Sandozschweiz from '../views/Sandoz-Schweiz.vue'
import Sandozglobal from '../views/Sandoz-Global.vue'
import Lungezuerich from '../views/Lunge-Zuerich.vue'
import Sidrosan from '../views/Sidrosan.vue'
import Sobu from '../views/Sobu.vue'
import RentARentner from '../views/Rent-A-Rentner.vue'
import Stadtspital from '../views/Stadtspital.vue'
import Steviasweet from '../views/Stevia-Sweet.vue'
import Nebelspalter from '../views/Nebelspalter.vue'
import SchutzUndRettung from '../views/SchutzUndRettung.vue'
import CookCouture from '../views/CookCouture.vue'
import Havelaar from '../views/Havelaar.vue'

export default [
  {
    name: 'home',
    path: '/',
    component: Home,
  },
  {
    path: '/works',
    redirect: {
      name: 'home',
      query: {
        scrollTo: 'works',
      },
    },
  },
  {
    name: 'about',
    path: '/about',
    component: About,
  },
  {
    path: '/imprint',
    component: Imprint,
  },
  {
    path: '/datenschutz',
    component: Datenschutz,
  },

  /* Cases */
  {
    name: 'case-cookcouture',
    path: '/cookcouture',
    component: CookCouture,
  },
  {
    name: 'case-havelaar',
    path: '/havelaar',
    component: Havelaar,
  },
  {
    name: 'case-stadtspital',
    path: '/stadtspital',
    component: Stadtspital,
  },
  {
    name: 'case-schutzundrettung',
    path: '/schutzundrettung',
    component: SchutzUndRettung,
  },
  {
    name: 'case-steviasweet',
    path: '/steviasweet',
    component: Steviasweet,
  },
  {
    name: 'case-nebelspalter',
    path: '/nebelspalter',
    component: Nebelspalter,
  },
  {
    name: 'case-teexpress',
    path: '/teexpress',
    component: Teeexpress,
  },
  {
    name: 'case-doppelleu',
    path: '/doppelleu',
    component: Doppelleu,
  },
  {
    name: 'case-viivhealthcare',
    path: '/viivhealthcare',
    component: Viivhealthcare,
  },
  {
    name: 'case-sandozschweiz',
    path: '/sandozschweiz',
    component: Sandozschweiz,
  },
  {
    name: 'case-sandozglobal',
    path: '/sandozglobal',
    component: Sandozglobal,
  },
  {
    name: 'case-lungezuerisch',
    path: '/lungezuerisch',
    component: Lungezuerich,
  },
  {
    name: 'case-sidrosan',
    path: '/sidrosan',
    component: Sidrosan,
  },
  {
    name: 'case-sobu',
    path: '/sobu',
    component: Sobu,
  },
  {
    name: 'case-rentarentner',
    path: '/rentarentner',
    component: RentARentner,
  },
]
