var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"VueCarousel-navigation"},[_c('a',{staticClass:"VueCarousel-navigation-button VueCarousel-navigation-prev",class:{
      'VueCarousel-navigation--disabled': !_vm.canAdvanceBackward,
      'VueCarousel-navigation--is-full-width': !_vm.canAdvanceForward,
    },style:(`padding: ${_vm.clickTargetSize}px; margin-right: -${_vm.clickTargetSize}px;`),attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(_vm.prevLabel)},on:{"click":function($event){$event.preventDefault();return _vm.triggerPageAdvance('backward')}}}),_vm._v(" "),_c('a',{staticClass:"VueCarousel-navigation-button VueCarousel-navigation-next",class:{
      'VueCarousel-navigation--disabled': !_vm.canAdvanceForward,
      'VueCarousel-navigation--is-full-width': !_vm.canAdvanceBackward,
    },style:(`padding: ${_vm.clickTargetSize}px; margin-left: -${_vm.clickTargetSize}px;`),attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(_vm.nextLabel)},on:{"click":function($event){$event.preventDefault();return _vm.triggerPageAdvance()}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }