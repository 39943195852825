<template>
  <div class="VueCarousel-navigation" v-if="!isTouch">
    <a
      href="#"
      class="VueCarousel-navigation-button VueCarousel-navigation-prev"
      v-on:click.prevent="triggerPageAdvance('backward')"
      v-bind:class="{
        'VueCarousel-navigation--disabled': !canAdvanceBackward,
        'VueCarousel-navigation--is-full-width': !canAdvanceForward,
      }"
      v-bind:style="`padding: ${clickTargetSize}px; margin-right: -${clickTargetSize}px;`"
      v-html="prevLabel"
    ></a>
    <a
      href="#"
      class="VueCarousel-navigation-button VueCarousel-navigation-next"
      v-on:click.prevent="triggerPageAdvance()"
      v-bind:class="{
        'VueCarousel-navigation--disabled': !canAdvanceForward,
        'VueCarousel-navigation--is-full-width': !canAdvanceBackward,
      }"
      v-bind:style="`padding: ${clickTargetSize}px; margin-left: -${clickTargetSize}px;`"
      v-html="nextLabel"
    ></a>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  data() {
    return {
      parentContainer: this.$parent,
    }
  },
  props: {
    /**
     * Amount of padding to apply around the label in pixels
     */
    clickTargetSize: {
      type: Number,
      default: 8,
    },
    /**
     * Text content of the navigation next button
     */
    nextLabel: {
      type: String,
      default: '▶',
    },
    /**
     * Text content of the navigation prev button
     */
    prevLabel: {
      type: String,
      default: '◀',
    },
  },
  computed: {
    canAdvanceForward() {
      return this.parentContainer.canAdvanceForward || false
    },
    canAdvanceBackward() {
      return this.parentContainer.canAdvanceBackward || false
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
  },
  methods: {
    triggerPageAdvance(direction) {
      if (direction) {
        this.$parent.advancePage(direction)
      } else {
        this.$parent.advancePage()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.VueCarousel-navigation-button {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  text-decoration: none;
  opacity: 0;
  transform: none;
  width: 50%;
  height: 100%;
}

.VueCarousel-navigation-next {
  right: 0;
  cursor: url('~assets/theme_icons/arrow-right.png') 20 20, auto;
}

.VueCarousel-navigation-prev {
  left: 0;
  cursor: url('~assets/theme_icons/arrow-left.png') 20 20, auto;
}

.VueCarousel-navigation--disabled {
  opacity: 0;
  cursor: default;
}

/*
  .VueCarousel-navigation--is-full-width {
    width: 100%;
    z-index: 1;
  }
  */
</style>
<style lang="scss">
@import '~assets/scss/vars';

.VueCarousel {
  &.simple {
    .VueCarousel-navigation {
      position: absolute;
      width: 110px;
      height: 60px;
      bottom: 0;
      right: 0;

      a {
        background-size: contain !important;
        width: 40px !important;
        height: 40px !important;
        opacity: 1 !important;
        cursor: pointer !important;
        color: transparent !important;
        border-radius: 50% !important;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) !important;
        margin: 10px !important;

        &.VueCarousel-navigation-prev {
          background-image: url('~assets/theme_icons/arrow-left.png');
        }

        &.VueCarousel-navigation-next {
          background-image: url('~assets/theme_icons/arrow-right.png');
        }
      }
    }
  }

  &.small-navigation {
    .VueCarousel-navigation-prev,
    .VueCarousel-navigation-next {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
      background: $green;
      opacity: 0.3;
      transition: all 250ms ease-in-out;
      outline: none !important;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 1;
      }

      > .arrow {
        &::before {
          content: url('~assets/theme_icons/arrow-left.svg');
          width: 27px;
          display: inline-block;
        }
      }
    }

    .VueCarousel-navigation-next {
      left: auto;
      right: 0;
      width: 62px;

      > .arrow {
        &::before {
          transform: rotate(180deg);
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
