import { render, staticRenderFns } from "./Teexpress.vue?vue&type=template&id=1a7c8a92&scoped=true"
import script from "./Teexpress.vue?vue&type=script&lang=js"
export * from "./Teexpress.vue?vue&type=script&lang=js"
import style0 from "./Teexpress.vue?vue&type=style&index=0&id=1a7c8a92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7c8a92",
  null
  
)

export default component.exports