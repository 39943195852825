import { render, staticRenderFns } from "./Lunge-Zuerich.vue?vue&type=template&id=467709d8&scoped=true"
import script from "./Lunge-Zuerich.vue?vue&type=script&lang=js"
export * from "./Lunge-Zuerich.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467709d8",
  null
  
)

export default component.exports