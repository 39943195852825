<template>
  <div>
    <section id="start" class="black-icons">
      <div v-if="!isU900 && !isTouch" id="a-shader"></div>

      <div v-if="!isU900 && !isTouch" id="no-cursor-area"></div>

      <iframe
        v-show="!homeVideoIsPlayed"
        :src="'https://player.vimeo.com/video/' + (isU900 ? '413453342' : '411322867') + '?api=1&background=1&mute=0&loop=0&autoplay=0'"
        ref="homevideo"
        frameborder="0"
        id="video-start"
        class="video"
        :class="{ visible: homeVideoIsVisible, isTouch: isTouch }"
        title="Die Antwort Intro Showreel"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        playsinline
        muted
      ></iframe>

      <img v-show="showHeaderImg" class="mobile-header" src="~assets/home/header-mobile.png" />

      <div class="scroll-down-on-home-arrow" @click="scrollToWorks" @mouseenter="handleWorksArrowMouseEnter" @touch="scrollToWorks">
        <div class="arrow-extention"></div>
        <img class="details-arrow-down" src="~assets/theme_icons/arrow-down.svg" />
        <div class="font">
          <span><img src="~assets/home/01_w.svg" /></span>
          <span><img src="~assets/home/02_o.svg" /></span>
          <span><img src="~assets/home/03_r.svg" /></span>
          <span><img src="~assets/home/04_k.svg" /></span>
        </div>
      </div>
    </section>

    <section id="intro-text" class="black-icons">
      <p>
        Hier erhalten Sie die Antwort von einer mehrfach ausgezeichneten Kreativagentur in Zürich. Wir vereinen die digitale, social, real-life und klassische
        Welt zum ganzheitlichen Markenerlebnis für den maximalen Return on Investment.
      </p>
      <button-custom :style="{ marginTop: '40px' }" color="black" :align="'center'" :link="{ path: '/about', query: { scrollTo: 'leistungen' } }"
        >Unsere Leistungen</button-custom
      >
    </section>

    <section id="caseStudies">
      <heading>Cases</heading>
      <div class="caseStudies__list">
        <div v-for="caseStudie in caseStudies" :class="'caseScrollerCase-' + caseStudie.id">
          <router-link :to="caseStudie.href" v-on:click.native="setPageTransitionToCaseFromScroller">
            <div class="caseStudie" :class="{ isTouch: isTouch }">
              <img :src="caseStudie.img" />
              <div class="overlay"></div>
              <h3>{{ caseStudie.title }}</h3>
            </div>
          </router-link>
        </div>
        <div class="caseScrollerCase-ihrcase" @click="openContactForm">
          <a href="">
            <div class="caseStudie" :class="{ isTouch: isTouch }">
              <div class="overlay"></div>
              <h3>Hier könnte <br />Ihr Case sein</h3>
            </div>
          </a>
        </div>
      </div>
    </section>

    <heading color="green">Team</heading>

    <team-carousel></team-carousel>

    <section class="home-answers">
      <heading :align="'center'">Wir sind ihre Antwort auf</heading>
      <div class="home-answers__grid">
        <router-link
          class="home-answers__item md-25"
          :to="{ path: '/about', query: { scrollTo: 'leistung-strategie' } }"
          v-on:click.native="setPageTransitionToSlideFromBottom"
          >Strategie</router-link
        >
        <router-link
          class="home-answers__item md-25"
          :to="{ path: '/about', query: { scrollTo: 'leistung-branding' } }"
          v-on:click.native="setPageTransitionToSlideFromBottom"
          >Branding</router-link
        >
        <router-link
          class="home-answers__item md-25"
          :to="{ path: '/about', query: { scrollTo: 'leistung-kreation' } }"
          v-on:click.native="setPageTransitionToSlideFromBottom"
          >Kreation</router-link
        >
        <router-link
          class="home-answers__item md-25"
          :to="{ path: '/about', query: { scrollTo: 'leistung-content' } }"
          v-on:click.native="setPageTransitionToSlideFromBottom"
          >Content</router-link
        >
        <router-link
          class="home-answers__item md-50"
          :to="{
            path: '/about',
            query: { scrollTo: 'leistung-media--technologie' },
          }"
          v-on:click.native="setPageTransitionToSlideFromBottom"
          >Media & Technologie</router-link
        >
      </div>
    </section>

    <newsletter-signup></newsletter-signup>

    <section class="home-magazin">
      <heading color="transparent-green" :align="'center'">Magazin</heading>
      <p>Noch nicht genug von uns? Hier gehts zum Magazin.</p>
      <button-custom :style="{ marginTop: '40px' }" color="black" :align="'center'" link="https://magazin.dieantwort.com/">Zum Magazin </button-custom>
    </section>

    <section class="home-partner">
      <heading color="black" :align="'center'">Partner</heading>
      <div class="partner-list">
        <a href="https://yep.swiss/" target="_blank">
          <img src="~assets/home/partnerlogos/yep.svg" />
        </a>

        <a href="https://www.converto.com/" target="_blank">
          <img src="~assets/home/partnerlogos/converto.svg" />
        </a>
        <a href="https://mike.schwede.ch/" target="_blank">
          <img src="~assets/home/partnerlogos/mike-schwede-3.svg" />
        </a>
      </div>
    </section>

    <section class="scroll-top-bar">
      <div @click="scrollToTop">
        <img src="~assets/theme_icons/scroll-up-dark.svg" />
      </div>
    </section>

    <appfooter></appfooter>
  </div>
</template>

<script>
import { EventBus } from './../store/eventbus'
import * as PIXI from 'pixi.js'
import Player from '@vimeo/player'
import TeamCarousel from '../components/Carousel-Team.vue'
import NewsletterSignup from '../components/Newsletter-Signup.vue'
import Footer from './../components/Footer.vue'
import ButtonCustom from '../components/ButtonCustom.vue'
import Heading from '../components/Heading.vue'

export default {
  name: 'home',

  components: {
    appfooter: Footer,
    'team-carousel': TeamCarousel,
    'newsletter-signup': NewsletterSignup,
    'button-custom': ButtonCustom,
    heading: Heading,
  },

  data() {
    return {
      caseStudies: this.$store.state.cases,
      videoMuted: true,
      arrowIsOutAnimating: false,
      pixi: {
        size: [1920, 1080],
        ratio: 1920 / 1080,
        uniforms: {
          mousePos: {
            type: '2f',
            value: [0.0, 0.0],
          },
          relPos: {
            type: '2f',
            value: [0.0, 0.0],
          },
          dimensions: {
            type: 'v2',
            value: [1920, 1080],
          },
        },
      },
    }
  },

  computed: {
    homeVideoIsPlayed() {
      return this.$store.state.homeVideoIsPlayed
    },
    homeVideoIsVisible() {
      return this.$store.state.homeVideoIsVisible
    },
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    is578to900() {
      return this.$store.getters.is578to900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    showHeaderImg() {
      const isU900 = this.$store.getters.isTouch
      const homeVideoIsPlayed = this.$store.state.homeVideoIsPlayed
      const isTouch = this.$store.getters.isTouch

      return (isU900 && homeVideoIsPlayed) || (window.innerWidth < 900 && window.innerWidth > window.innerHeight) || (isTouch && homeVideoIsPlayed)
    },
  },

  methods: {
    openContactForm(e) {
      e.preventDefault()
      this.$store.dispatch('showContactOverlay')
    },
    scrollToTop() {
      TweenMax.to(window, 1, { scrollTo: { y: 0, autoKill: false } })
    },
    scrollToWorks() {
      TweenMax.to(window, 1, {
        scrollTo: { y: '#caseStudies', autoKill: false },
      })
    },
    setPageTransitionToCaseFromScroller(e) {
      this.$store.commit('setNextPageTransition', 'toCase--fromScroller')
    },
    setPageTransitionToSlideFromBottom() {
      this.$store.commit('setNextPageTransition', 'slideFromBottom')
    },
    waitForScrollIfWorksArrowHaveToAnimateOut() {
      var pos = window.pageYOffset || document.documentElement.scrollTop
      var arrowHeight = document.querySelector('.arrow-extention').style.height
      var arrowIsIn = arrowHeight == '' || arrowHeight == '30px'
      if (pos > 0 && arrowIsIn && !this.arrowIsOutAnimating) {
        this.animateWorksArrowOut()
      }
    },
    handleWorksArrowMouseEnter() {
      this.animateWorksArrowOut()
    },
    animateWorksArrowOut() {
      if (!this.isU900) {
        this.arrowIsOutAnimating = true
        var tl = new this.TimelineLite()
          .staggerTo('.scroll-down-on-home-arrow >.font >span', 0.3, { y: 20, alpha: 0 }, 0.05, 0.15)
          .to('.scroll-down-on-home-arrow .arrow-extention', 0.5, { y: 80, height: 100, ease: Power3.easeIn }, 0)
          .to('.scroll-down-on-home-arrow .details-arrow-down', 0.5, { y: 200, ease: Power3.easeIn }, 0)
          .call(() => {
            this.arrowIsOutAnimating = false
          })
      }
    },
    initCaseScrollerScrollAnimation() {
      var cases_els = []
      var cases_els_img = []
      for (let i in this.caseStudies) {
        let el = document.getElementsByClassName('caseScrollerCase-' + this.caseStudies[i].id)[0]
        el && cases_els.push(el)
        let img = el.querySelector('img')
        img && cases_els_img.push(img)
      }

      let placholder = document.getElementsByClassName('caseScrollerCase-ihrcase')[0]
      cases_els.push(placholder)
      cases_els_img.push(placholder)

      window.addEventListener('scroll', (event) => {
        for (let i in cases_els) {
          let img = cases_els_img[i]
          let pos = window.innerHeight - img.getBoundingClientRect().top - img.clientHeight / 2
          if (pos > 0 && pos < window.innerHeight * 0.9) {
            cases_els[i].classList.add('activeCaseInScroller')
          } else {
            cases_els[i].classList.remove('activeCaseInScroller')
          }
        }
      })
    },
    // setActiveCase(e) {
    // 	var el = e.target.parentElement.parentElement.parentElement;
    // 		el.classList.add('fixedHover');
    // 	document.querySelectorAll('.activeCaseInScroller').forEach((el, i)=>{
    // 		if(!el.classList.contains('fixedHover')) {
    // 			el.classList.remove('activeCaseInScroller');
    // 		}
    // 	});
    // 	if(!el.classList.contains('activeCaseInScroller')) {
    // 		el.classList.add('activeCaseInScroller');
    // 		TweenMax.fromTo('.activeCaseInScroller h3', 0.5, {alpha: 0, x: (window.innerWidth/2)}, {alpha: 1, x: 0, ease: Power1.easeOut}, 0);
    // 	}
    // },
    startFromPreloader() {
      !this.isTouch && this.initPixiApp()

      this.VimeoHeaderVideo.on(
        'ended',
        () => {
          this.$store.dispatch('animateWorkArrowIn')
          this.$store.commit('setHomeVideoToInvisible')
        },
        false
      )

      window.scrollTo(0, 0)

      var tl = new this.TimelineLite()
        .to('#preloader .wird-geladen', 0.6, {
          alpha: 0,
          'pointer-events': 'none',
        })
        .to('#preloader > img', 0.5, { alpha: 0, 'pointer-events': 'none' }, 0.3)
        .to('#preloader', 1, { alpha: 0, 'pointer-events': 'none' }, 0.9)
        .call(
          () => {
            this.$store.dispatch('allowScroll')
            EventBus.$emit('HomeLoaded')
            if (this.$route.query.scrollTo && this.$route.query.scrollTo == 'works') {
              TweenMax.to(window, 1, {
                scrollTo: { y: '#caseStudies', autoKill: false },
              })
            } else {
              window.scrollTo(0, 0)
            }
            this.VimeoHeaderVideo.play()
          },
          this,
          [],
          1
        )
        .call(
          () => {
            if (this.$store.state.homeVideoIsPlayed && !this.isU900) {
              this.animateWorksArrowOut()
            }
          },
          this,
          [],
          4
        )
    },

    handleFirstLoad() {
      var minimumPreloadTime = 4300
      var preLoadTime = new Date().getTime() - window.preloaderStarttime
      if (preLoadTime > minimumPreloadTime) {
        this.startFromPreloader()
      } else {
        var duration = minimumPreloadTime - preLoadTime
        setTimeout(() => {
          this.startFromPreloader()
        }, duration)
      }
    },

    /****************************/
    /* a-Shader */
    /****************************/
    initPixiApp() {
      this.pixi.app = new PIXI.Application({
        width: this.pixi.size[0],
        height: this.pixi.size[1],
        backgroundAlpha: true,
      })
      document.getElementById('a-shader').appendChild(this.pixi.app.view)

      this.pixi.app.loader
        .add(require('./../assets/shader/a-blurred.jpg'))
        .add(require('./../assets/shader/a-foreground.png'))
        .add(require('./../assets/shader/fragment_shader_home.glsl'))
        .load(this.initAShader)

      PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH

      this.updateAShader()
    },

    initAShader() {
      var container,
        bg,
        fg,
        circleShaderCode,
        circleShader,
        fxaaFilter,
        w = this.pixi.app.renderer.width,
        h = this.pixi.app.renderer.height

      container = new PIXI.Container()
      this.pixi.app.stage.addChild(container)

      bg = PIXI.Sprite.from(require('./../assets/shader/a-blurred.jpg'))
      bg.width = w
      bg.height = h
      container.addChild(bg)

      fg = PIXI.Sprite.from(require('./../assets/shader/a-foreground.png'))
      fg.width = w
      fg.height = h
      this.pixi.app.stage.addChild(fg)

      circleShaderCode = this.pixi.app.loader.resources[require('./../assets/shader/fragment_shader_home.glsl')].data
      circleShader = new PIXI.Filter('', circleShaderCode, this.pixi.uniforms)
      container.filters = [circleShader]

      fxaaFilter = new PIXI.filters.FXAAFilter()
      this.pixi.app.stage.filters = [fxaaFilter]
    },

    updateAShader() {
      var mouseData = this.pixi.app.renderer.plugins.interaction.mouse.global

      this.pixi.uniforms.mousePos.value[0] = mouseData.x
      this.pixi.uniforms.mousePos.value[1] = mouseData.y
      this.pixi.uniforms.relPos.value[0] = (1 / this.pixi.size[0]) * this.pixi.uniforms.mousePos.value[0]
      this.pixi.uniforms.relPos.value[1] = (1 / this.pixi.size[1]) * this.pixi.uniforms.mousePos.value[1]

      this.pixi.app.renderer.render(this.pixi.app.stage)

      setTimeout(() => {
        requestAnimationFrame(this.updateAShader)
      }, 10)
    },
    pausePixi() {
      if (this.pixi.app && !this.isTouch) {
        this.pixi.app.stop()
      }
    },
    playPixiAgain() {
      if (this.pixi.app && !this.isTouch) {
        this.pixi.app.start()
      }
    },
    setHeaderheight() {
      TweenMax.set('#start', { height: window.innerHeight })
    },
  },
  activated() {
    this.$store.dispatch('resetHome')
    if (!this.isU900) {
      this.initCaseScrollerScrollAnimation()
    }
    !this.isU900 && window.addEventListener('scroll', this.waitForScrollIfWorksArrowHaveToAnimateOut)
    this.playPixiAgain()
  },
  deactivated() {
    !this.isU900 && window.removeEventListener('scroll', this.waitForScrollIfWorksArrowHaveToAnimateOut)
    this.$store.commit('removeScrollMagicScenes', 'home')
    this.$store.commit('setHomeVideoPlayed')
    this.$store.commit('setHomeVideoToInvisible')
    this.pausePixi()
  },
  mounted() {
    this.$store.dispatch('forbidScroll', true)

    if (this.isTouch) {
      this.setHeaderheight()
      var lastWindowWidth = window.innerWidth
      window.addEventListener('resize', (e) => {
        if (lastWindowWidth !== window.innerWidth) {
          this.setHeaderheight()
          lastWindowWidth = window.innerWidth
        }
      })
    }

    this.VimeoHeaderVideo = new Player(this.$refs.homevideo)

    this.VimeoHeaderVideo.getDuration().then((duration) => {
      this.headerVideoDuration = duration
    })

    this.VimeoHeaderVideo.on('loaded', () => {
      this.handleFirstLoad()
    })

    this.VimeoHeaderVideo.on('ended', () => {
      this.$store.commit('setHomeVideoPlayed')
    })

    EventBus.$on('stopHomeVideo', () => {
      this.VimeoHeaderVideo.pause()
      this.VimeoHeaderVideo.setCurrentTime(0)
    })
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';

#start {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #fff;

  #video-start {
    height: 56.25vw;
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 1;
    pointer-events: none;

    @media (max-aspect-ratio: 16 / 9) {
      height: 100vh;
      width: 177.77vh;
    }

    &.visible {
      display: block;
    }

    &.isTouch {
      position: relative;
      z-index: 1;
    }

    // mobil video
    @media (max-width: 900px) {
      width: 100vw;
      height: 216.533vw;
      top: -2px;
      transform: translateX(-50%);
    }

    // mobil video - quer
    @media (max-width: 900px) and (orientation: landscape) {
      display: none !important;
    }
  }

  .mobile-header {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;

    @media (max-aspect-ratio: 16 / 9) {
      max-width: none;
      max-height: 100%;
    }

    @media (orientation: landscape) {
      max-height: none;
    }
  }
}

#intro-text {
  background-color: $green;
  padding: 75px 30px;

  p {
    font-weight: 500;
    text-align: center;
    font-family: Favorit, sans-serif;
    margin: 0 auto;
    max-width: 920px;
    font-size: 30px;

    @media (max-width: 577px) {
      font-size: 24px;
    }
  }
}

#caseStudies {
  .caseStudies__list {
    background: #000;
    text-align: center;

    @media (min-width: 800px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .activeCaseInScroller {
      .caseStudie {
        h3 {
          opacity: 1;
          transform: translateY(0);
          padding: 0 20px;
        }
      }

      &.fixedHover {
        .caseStudie {
          > img {
            filter: grayscale(0) !important;
          }

          > .overlay {
            opacity: 0.2 !important;
          }
        }
      }
    }

    .caseScrollerCase-ihrcase {
      background-color: #aaa;

      &:hover {
        background-color: $green;

        h3 {
          color: #000;
        }

        .overlay {
          opacity: 0 !important;
        }
      }
    }

    .caseStudie {
      width: 100vw;
      height: 56vw;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0;

      @media (min-width: 800px) {
        width: 50vw;
        height: 28.125vw;
      }

      h3 {
        color: #fff;
        font-size: 60px;
        pointer-events: none;
        transition: all 500ms ease-out;
        opacity: 0;
        transform: translateY(50px);
        padding: 0 20px;
      }

      > img {
        filter: grayscale(1);
        transition: all 250ms;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      > .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
        transition: all 250ms ease-in-out;
      }

      @media (max-width: 900px) {
        h3 {
          opacity: 1;
          transform: none;
          z-index: 1;
          font-size: 7vw;
          letter-spacing: 0.3vw;
          line-height: 9vw;
          padding: 0 15px;
        }

        > img {
          transform: none;
        }

        > .overlay {
          opacity: 0.3;
        }
      }

      &:not(.isTouch) {
        transform: scale(1);

        &:hover {
          > img {
            filter: grayscale(0);
          }

          > .overlay {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

.scroll-down-on-home-arrow {
  width: 100px;
  position: absolute;
  left: calc(50% - 50px);
  padding-bottom: 60px;
  text-align: center;
  cursor: pointer;
  bottom: 0;
  z-index: 1;

  .arrow-extention {
    background: #000;
    width: 4px;
    height: 30px;
    position: absolute;
    left: calc(50% - 2px);
  }

  .details-arrow-down {
    width: 30px;
    margin-bottom: 60px;
  }

  .font {
    position: relative;

    span {
      position: relative;
      display: inline-block;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 0;

    .font {
      display: none;
    }
  }
}

#no-cursor-area {
  height: 75vh;
  width: 75vh;
  cursor: none;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-answers {
  background-color: #000;
  padding-right: 30px;
  padding-left: 30px;

  &__grid {
    display: flex;
    margin-bottom: 60px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }

  .md-25 {
    margin-right: 15px;
    margin-left: 15px;
    width: calc(25% - 60px);

    @media (max-width: 1000px) {
      width: calc(50% - 60px);
    }

    @media (max-width: 560px) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .md-50 {
    width: calc(50% - 60px);

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  &__item {
    background-color: $green;
    text-transform: uppercase;
    padding: 15px;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;
    transition: background-color 100ms ease-in-out;

    &:hover {
      background-color: rgba($green, 0.7);
    }
  }
}

.home-magazin {
  padding-bottom: 100px;
  padding-right: 30px;
  padding-left: 30px;
  background-image: url('~assets/home/magazin.jpg');
  background-size: cover;
  background-position: center;

  p {
    text-align: center;
    font-size: 30px;
    margin-top: 0;
    color: #fff;
  }
}

.home-partner {
  background-color: #000;

  .partner-list {
    max-width: 1200px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 100px;
    flex-wrap: wrap;

    a {
      display: block;
      padding: 0 30px;
      margin-bottom: 60px;
    }

    img {
      height: 45px;
    }
  }
}
</style>

<style lang="scss">
#a-shader {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  canvas {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;

    @media (max-aspect-ratio: 16 / 9) {
      max-width: none;
      max-height: 100%;
    }
  }
}
</style>
